import configDev from './config.dev';
import configProd from './config.prod';
import configUat from './config.uat';

let config;

if (process.env.REACT_APP_ENV === 'production') {
    config = configProd;
  } else if (process.env.REACT_APP_ENV === 'uat') {
    config = configUat; // Assuming you have a separate configuration for UAT
  } else {
    config = configDev;
  }

// Use the configuration object as needed
//#PROD logger.log(config.websiteUrl);
//#PROD logger.log(config.REDIRECT_SIGNIN);
//#PROD logger.log(config.REDIRECT_SIGNOUT);
// import dotenv from 'dotenv';

// // Load the environment variables based on the NODE_ENV value
// dotenv.config({
//   path: process.env.NODE_ENV === 'production' ? '.env.prod' : '.env.dev',
// });
//import config from './config.dev.js'; // Import the appropriate configuration file based on your environment

//import configProd from './config.prod'; // For development environment
////#PROD logger.log("`${config.cognito.callbackUrl}`", `${config.cognito.redirectSignIn}`);
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": `${config.awsRegion}`,
    "aws_cognito_region": `${config.cognito.awsCognitoRegion}`,
    "aws_user_pools_id": `${config.cognito.cognitoUserPoolId}`,
    "aws_user_pools_web_client_id": `${config.cognito.cognitoUserPoolWebClientId}`,
    "oauth": {
        "domain": `${config.cognito.cognitoDomain}`,
        "scope": ["email", "openid", "profile"],
        // "redirectSignIn": process.env.REDIRECT_SIGNIN,
        // "redirectSignOut": process.env.REDIRECT_SIGNOUT,

        // Use the config values in your code
        "redirectSignIn": `${config.cognito.redirectSignIn}`, // Use the imported callback URL
        "redirectSignOut": `${config.cognito.redirectSignOut}`, // Use the imported logout URL
        // "redirectSignIn": "http://exam.kodinghut.in", // Update with your redirect URL
        // "redirectSignOut": "http://exam.kodinghut.in", // Update with your redirect URL
        // "redirectSignIn": 'http://localhost:3000/callback',
        // "redirectSignOut": 'http://localhost:3000/logout',
        "responseType": "code",
        "authProviderConfigs": {
            "Google": {
                "clientId": `${config.google.googleClientId}`,
//                "clientSecret": `${config.google.googleClientSecret}`
            },
            "Facebook": {
                "appId": `${config.facebook.facebookAppId}`,
//                "appSecret": `${config.facebook.facebookAppSecret}`,
              },
        }
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "Google"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};

export default awsmobile;
