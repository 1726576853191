import { addMyCourses } from "../../../services";

export const processWalletPayment = async (cartItems, totalPrice, myCredits) => {
    if (myCredits < totalPrice) {
        return {
            success: false,
            message: "Insufficient credits. Please use another payment method.",
        };
    }

    try {
        const response = await addMyCourses(cartItems);
        if (response.status === 200) {
            return { success: true, message: "Wallet payment successful!" };
        }
        return { success: false, message: "Failed to process wallet payment." };
    } catch (error) {
        return { success: false, message: "Error processing wallet payment." };
    }
};
