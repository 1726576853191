import { phonepeCreateOrder } from "../../../services";

export const processPhonePePayment = async (totalPrice, onSuccess, onFailure) => {
    try {
        // Prepare payment data
        const amount = JSON.stringify({ amount: totalPrice * 100 });
        const response = await phonepeCreateOrder(amount);

        const order = await response;

        // PhonePe redirects or opens their UI for payment handling
        const paymentUrl = order.paymentUrl;
        if (paymentUrl) {
            window.location.href = paymentUrl;
        } else {
            throw new Error("Failed to initiate PhonePe payment.");
        }
    } catch (error) {
        console.error("Error initializing PhonePe payment: ", error);
        onFailure("Error initializing PhonePe payment.");
    }
};
