import React from "react";
import { Box, Typography, Grid, Paper, CircularProgress } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const TutorDashboard = () => {
  // Mock Data
  const coursesData = [
    { name: "Jan", Created: 3 },
    { name: "Feb", Created: 5 },
    { name: "Mar", Created: 2 },
    { name: "Apr", Created: 6 },
    { name: "May", Created: 4 },
  ];

  const studentEngagementData = [
    { name: "High Engagement", value: 65 },
    { name: "Moderate Engagement", value: 25 },
    { name: "Low Engagement", value: 10 },
  ];

  const tutorImpact = 85; // Percentage of students positively impacted
  const coursesCreated = 20; // Total number of courses created
  const topPerformancePercentage = 70; // Percentage of students scoring above 75%
  const engagementColors = ["#0088FE", "#00C49F", "#FFBB28"];

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Tutor Dashboard
      </Typography>

      {/* Motivational Insights */}
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
        <Typography variant="h6" color="primary">
          Your Courses Are Making a Difference!
        </Typography>
        <Typography>
          You&apos;ve positively impacted {tutorImpact}% of your students. 
        </Typography>
        <Typography color="text.secondary">
          You&apos;ve created {coursesCreated} courses so far. Keep inspiring and sharing knowledge!
        </Typography>
        <Typography>
          {topPerformancePercentage}% of your students have scored above 75%. Amazing work!
        </Typography>
      </Paper>

      {/* Dashboard Metrics */}
      <Grid container spacing={4}>
        {/* Bar Chart: Monthly Courses Created */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Monthly Courses Created
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={coursesData}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="Created" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Pie Chart: Student Engagement */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Student Engagement Levels
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={studentEngagementData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label={({ name, value }) => `${name}: ${value}%`}
                >
                  {studentEngagementData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={engagementColors[index]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Circular Progress: Tutor Impact */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 3, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              Positive Student Impact
            </Typography>
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress variant="determinate" value={tutorImpact} size={150} />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6">{tutorImpact}%</Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TutorDashboard;
